<template>
  <div>
    <!-- 宽带订单 -->
    <area-tree @setQueryParams="handleQuery" :treeShow="url ? false : true">
        <!-- 搜索栏 -->
      <el-form class="params-wrap" size="small" :inline="true" :model="queryParams">
        <el-form-item>
          <el-input v-model="queryParams.schoolName" clearable placeholder="搜索套餐名称/ID"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="info" icon="el-icon-search" >搜索</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="info" >生成报表</el-button>
        </el-form-item>
      </el-form>

      <div class="content-wrap">
        <div class="btn-wrap cfx">
          <el-button type="success" size="small" icon="el-icon-plus" >添加套餐</el-button>
        </div>
     <!--列表-->
        <paged-table
          :data="bannerList"
          :total="bannerTotal"
          :loading="tLoading"
          :isShowSelection="false"
          defaultSortProp="id"
          v-model="queryParams"
        >
          <el-table-column
            prop="title"
            label="订单号"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="title"
            label="用户"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="title"
            label="学号"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="title"
            label="学校"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="title"
            label="手机号"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
        
          <el-table-column
            prop="type"
            label="融合套餐"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="url"
            label="宽带套餐"
            :show-overflow-tooltip="true"
          >
          </el-table-column>
          <el-table-column
            prop="status"
            label="宿舍号"
            :show-overflow-tooltip="true"
          >
          </el-table-column>

            <el-table-column
            prop="status"
            label="联系电话"
            :show-overflow-tooltip="true"
          >
          </el-table-column>

          <el-table-column
            prop="status"
            label="下单时间"
            :show-overflow-tooltip="true"
          >
          </el-table-column>

          <el-table-column
            prop="status"
            label="处理状态"
            :show-overflow-tooltip="true"
          >
          </el-table-column>

          <el-table-column
            prop="status"
            label="处理人"
            :show-overflow-tooltip="true"
          >
          </el-table-column>

            <el-table-column
            prop="status"
            label="处理时间"
            :show-overflow-tooltip="true"
          >
          </el-table-column>

          <el-table-column
            align="right"
            label="操作"
            width="120"
            fixed="right"
          >
            <template >
              
              <el-button
                size="mini"
                type="primary"
                icon="el-icon-edit"
                title="编辑"
              ></el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete"
                title="删除"
              ></el-button>
            </template>
          </el-table-column>
        </paged-table>
      </div>
    </area-tree>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { mapActions, mapState } from "vuex";
import PagedTable from "@/components/common/PagedTable";
import BaseImport from "@/components/common/BaseImport";
import AreaTree from "@/components/common/AreaTree";
import pageMixin from "@/mixins/pageMixin";
import provinces from "@/mixins/provinces";
import download from "@/mixins/download";
import { BaseUrl } from "@/common/config";
import { log, mergeObject } from '@/common/utils'
export default {
  name:'broadBandOrder',
  components: {
    AreaTree,
    PagedTable
  },
  mixins: [pageMixin,provinces,download],
  data() {
    return {
      url: '',
      BaseUrl,
      queryParams: {
        cooperate: null,
        schoolName: null
      },
      schoolType: [],
      form:{
        id:'',
        schoolName:'',
        department:'',
        province:'',
        city:'',
        area:'',
        level: '',
        remark: ''
      },
      details:{
        schoolName:'',
        department:'',
        extend:{},
        remark: ''
      },
      

      
    };
  },
  computed: {
    ...mapState(["OA"]),
  },
  methods: {
    handleQuery(data){
      // log(data)
      if ((data.id + "").length === 9) {
        this.queryParams.province = data.id;
      }
      if ((data.id + "").length === 12) {
        this.queryParams.city = data.id;
      }
      if ((data.id + "").length === 15) {
        this.queryParams.area = data.id;
      }
    },
  },
  mounted() {
    this.url = window.location.href.includes('sim') || window.location.href.includes('20033')
  }
  
};
</script>