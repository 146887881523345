import { log } from "@/common/utils.js";
export default {
  data: function() {
    return {
      prefix:'http://qtxy.oss-cn-hangzhou.aliyuncs.com/'
    };
  },
  methods: {
    async downloadPath(path){
      if(!path){
        this.$message.warning('文档不存在')
        return;
      }
      try {
        await this.$confirm('确认下载该文件么');
        path.includes('aliyuncs.com') ? window.open(path) : window.open(this.prefix + path);
      } catch (e) {
        log(e);
      }
    }
  }
};
