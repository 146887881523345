<template>
<!-- 区域树组件 -->
  <div class="col-wrap wrap cfx">
    <div class="col-tree" v-if="treeShow && !!dataTree">
      <div class="hd">地区选择</div>
      <div class="bd page">
        <el-tree
            :data="dataTree"
            accordion
            :props="defaultProps"
            @node-click="handleClick"
            default-expand-all
          ></el-tree>
      </div>
    </div>
    <div :class="treeShow && !!dataTree?'col-main':''">
      <slot></slot>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { commonDictGetAllDictTreeFCode } from "@/api/system/dict";
import { log, mergeObject } from '@/common/utils'
import pageMixin from "@/mixins/pageMixin";
import membersSchoolList from "@/api/system/school";
import { mapState, mapActions } from "vuex";
export default {
  name: "AreaTree",
  mixins: [pageMixin],
  props: {
    treeShow: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      queryParams: {
        province: null,
        city: null,
        area: null
      },
      dataTree: [],
      defaultProps: {
        children: "children",
        label: "name",
      },
    };
  },
  created() {
    this.areaTree("area_cn")
  },
  methods: {
    // ...mapActions("systemDict",[ "commonDictGetAllDictTreeFCode" ]),
    ...mapActions("options",[ "getSchoolTree" ]),
    // 获取侧边栏区域树
    async areaTree(val){
      this.dataTree = [];
      if(!val) return;
      this.dataTree = await this.getSchoolTree({code: val})
      // log(this.dataTree)
    },
    // 树型节点查询
    handleClick(data, node) {
      this.$emit('setQueryParams', data)
    },
  }
};
</script>
<style scoped lang="scss">
.col-wrap {
  .col-tree { position: absolute; left:0; bottom:0; top:0; width:180px; background:#fff; border-radius:10px;
    .hd { height:40px; border-bottom:#ddd 1px solid; line-height:40px; padding:0 10px; font-size:16px; }
    .bd { top:40px; overflow-y:auto; overflow-x:hidden; }
  }
  .col-main { padding-left:200px; }
}
</style>