/* eslint-disable no-unused-vars */
import { mapState, mapActions } from "vuex";
import { log } from '@/common/utils'
export default {
  data: function() {
    return {
      cityOptions:[],
      areaOptions:[]
    };
  },
  computed: {
    ...mapState(["provinceOptions"]),
  },
  methods: {
    ...mapActions("systemDict",[ "commonDictGetDictFPid" ]),
    async provinceChange(val, str){
      str = '';
      this.cityOptions = [];
      if(!val) return;
      this.cityOptions = await this.commonDictGetDictFPid({pid: val})
      log(this.cityOptions)
    },
    async cityChange(val, str){
      str = '';
      this.areaOptions = [];
      if(!val) return;
      this.areaOptions = await this.commonDictGetDictFPid({pid: val})
      log(this.areaOptions)
    }
  }
};
